import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import BlocksRenderer from "../components/blocks-renderer";
import Seo from "../components/seo";
import { Link } from "gatsby";

const ArticlePage = ({ data }) => {
  const article = data.strapiArticle;

  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.cover,
  };

  console.log(article);

  return (
    <Layout as="article">
      <Seo seo={seo} />
      <header className="container max-w-4xl py-8">
        {article.category && (
          <Link
            to={`/categories/${article.category.slug}`}
            className="mt-4 text-2xl text-accent"
          >
            {article.category.name}
          </Link>
        )}

        <h1 className="text-6xl font-bold text-neutral-700">{article.title}</h1>
        <p className="mt-4 text-2xl text-neutral-500">{article.description}</p>

        {article.cover ? (
          <GatsbyImage
            image={getImage(article?.cover?.localFile)}
            alt={article?.cover?.alternativeText}
            className="mt-6"
          />
        ) : null}
      </header>
      <main className="mt-8">
        <div className="container max-w-4xl ">
          {article.audio ? (
            <p className="mb-2 italic">Аудиоверсия</p>
          ) : (
            <p className="mb-2 italic">Аудиоверсия скоро будет доступна</p>
          )}
          {article.audio ? (
            <audio controls>
              <source src={article.audio.url} type="audio/mpeg" />
            </audio>
          ) : (
            <audio controls></audio>
          )}
        </div>
        <BlocksRenderer blocks={article.blocks || []} />
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      id
      slug
      title
      description
      blocks {
        ...Blocks
      }
      cover {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      category {
        name
        slug
      }
      audio {
        url
      }
    }
  }
`;

export default ArticlePage;
