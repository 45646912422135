import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BlockMedia = ({ data }) => {
  const hasFile = Boolean(data.file);
  return null;
  return (
    <GatsbyImage
      image={getImage(hasFile ? data.file.localFile : "null")}
      alt={hasFile ? data.file.alternativeText : "alt text"}
    />
  );
};

export default BlockMedia;
